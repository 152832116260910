import { Component, EventEmitter, Input, Output } from '@angular/core'

import { DateFormat } from '../../../../../../core/enums/global/date-format'
import { SharedModule } from '../../../../../../shared/modules/shared.module'

@Component({
  selector: 'app-republish-order',
  templateUrl: './republish-order.component.html',
  styleUrls: ['./republish-order.component.scss'],
  imports: [SharedModule],
  animations: []
})
export class RepublishOrderComponent {
  @Input() name: string
  @Input() confirmationDueDate: string | null

  @Output() close: EventEmitter<boolean> = new EventEmitter()

  get DateFormat() {
    return DateFormat
  }

  onClose(shouldRepublish: boolean = false): void {
    this.close.emit(shouldRepublish)
  }
}
