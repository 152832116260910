<app-overlay appFloatingElement (close)="onClose(false)">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose(false)">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button
      *ngIf="
        (orderStatus === OrderStatus.NotSet && paidStatus !== PaidStatus.Exempted) || paidStatus === PaidStatus.NotPaid
      "
      theme="dark"
      class="next-button"
      (click)="onClose(true)"
    >
      {{ 'payment.send_reminder' | translate }}
    </app-button>
  </div>

  <div class="overlay__header">
    <h2>{{ 'payment.reminder_history_for' | translate }} {{ userName }}</h2>
    <p class="sub-header ns-text small">{{ dugnadName }}</p>
  </div>

  <div class="overlay__body">
    <div class="ns-description ns-text small">
      <p>{{ 'economy.reminder_history_description_1' | translate }}</p>
      <p>{{ 'economy.reminder_history_description_2' | translate }}</p>
      <p>{{ 'economy.reminder_history_description_3' | translate }}</p>
      <p>{{ 'economy.reminder_history_description_4' | translate }}</p>
    </div>

    <app-table
      [data]="table.data"
      [columns]="table.columns"
      [loading]="table.loading"
      [paginator]="false"
      [search]="false"
    >
      <ng-container [matColumnDef]="Columns.Receiver">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.receiver' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="clickable">
          <button class="ns-user-cell" (click)="openUserProfile(row.receiver.id)">
            <div class="ns-avatar-wrapper">
              <app-profile-picture [user]="row.receiver"></app-profile-picture>
              <app-user-activation-status
                [status]="row.receiver.activation_status"
                [supervised]="row.receiver.supervised_by_parents"
              ></app-user-activation-status>
            </div>
            <span class="ns-name">{{ row.receiver.first_name }} {{ row.receiver.last_name }}</span>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Type">
        <mat-header-cell *matHeaderCellDef>{{ 'table.reminder_type' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" [ngSwitch]="row.reminder_type">
          <ng-container *ngSwitchCase="ReminderType.Confirmation">{{ 'economy.confirmation_reminder' | translate }}</ng-container>
          <ng-container *ngSwitchCase="ReminderType.PickUp">{{ 'economy.pick_up_reminder' | translate }}</ng-container>
          <ng-container *ngSwitchCase="ReminderType.Payment">{{ 'economy.payment_reminder' | translate }}</ng-container>
        </mat-cell>
      </ng-container>
  
      <ng-container [matColumnDef]="Columns.Date">
        <mat-header-cell *matHeaderCellDef>{{ 'table.date' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <p class="ns-date">{{ row.created_at | date: DateFormat.shortDateTime }}</p>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Email">
        <mat-header-cell *matHeaderCellDef>{{ 'table.email' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <ng-container
            [ngTemplateOutlet]="booleanTemplate"
            [ngTemplateOutletContext]="{ value: row.delivered_by_email, error: row.error_message }"
          ></ng-container>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.PushNotification">
        <mat-header-cell *matHeaderCellDef>{{ 'table.push_notification' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <ng-container
            [ngTemplateOutlet]="booleanTemplate"
            [ngTemplateOutletContext]="{ value: row.delivered_by_push, error: row.error_message }"
          ></ng-container>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.SMS">
        <mat-header-cell *matHeaderCellDef>{{ 'table.sms' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <ng-container
            [ngTemplateOutlet]="booleanTemplate"
            [ngTemplateOutletContext]="{ value: row.delivered_by_sms, error: row.error_message }"
          ></ng-container>
        </mat-cell>
      </ng-container>
    </app-table>
  </div>
</app-overlay>

<ng-template let-value="value" let-error="error" #booleanTemplate>
  <ng-container [ngSwitch]="value">
    <div *ngSwitchCase="true" class="ns-boolean green">
      <mat-icon>check</mat-icon>
    </div>
    <div *ngSwitchCase="null" class="ns-boolean gray">
      <mat-icon>remove</mat-icon>
    </div>
    <div
      *ngSwitchCase="false"
      [matTooltip]="error"
      [matTooltipDisabled]="!error"
      matTooltipClass="ns-light"
      matTooltipPosition="above"
      class="ns-boolean red"
    >
      <mat-icon>close</mat-icon>
    </div>
  </ng-container>
</ng-template>
