<app-modal (modalClose)="onClose()">
  <button class="modal__close-button" mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <h3 class="modal__header">{{ 'economy.republish_order_for' | translate : { name: name } }}?</h3>
  <div class="modal__body ns-text">
    {{
      'economy.republish_order_description'
        | translate : { date: confirmationDueDate | date : DateFormat.shortDate, name: name }
    }}
  </div>
  <div class="modal__button-wrapper">
    <app-button theme="transparent" size="small" (click)="onClose()">
      {{ 'action.no_go_back' | translate }}
    </app-button>
    <app-button size="small" (click)="onClose(true)">
      {{ 'action.yes_republish' | translate }}
    </app-button>
  </div>
</app-modal>
