import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { MatTableModule } from '@angular/material/table'
import { Store } from '@ngxs/store'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize } from 'rxjs/operators'

import { DugnadReminderHistoryResponse } from './interfaces/dugnad-reminder-history-response'
import { DugnadsService } from './../../../../../core/api/dugnads.service'
import { GroupSelectorState } from './../../../../../shared/modules/group-selector/states/group-selector.state'
import { OverlayAnimations } from './../../../../../shared/components/overlay/animations'
import { ReminderType } from '../enums/reminder-type'
import { SharedModule } from './../../../../../shared/modules/shared.module'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { SelectedGroup } from '../../../../../core/interfaces/organization/selected-group'
import { OrderStatus } from '../interfaces/order-status'
import { PaidStatus } from '../enums/paid-status'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { Table } from '../../../../../core/classes/table/table'
import { ToggleUserProfile } from '../../../../../shared/modules/user-profile/state/user-profile.actions'

@Component({
  selector: 'app-dugnad-reminder-history',
  templateUrl: './dugnad-reminder-history.component.html',
  styleUrls: ['./dugnad-reminder-history.component.scss'],
  imports: [MatTableModule, SharedModule, TableComponent],
  host: { '[@dugnadReminderHistoryAnimation]': '' },
  animations: [trigger('dugnadReminderHistoryAnimation', OverlayAnimations.detailsPopup)]
})
export class DugnadReminderHistoryComponent implements OnInit {
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup

  @Input() userName: string
  @Input() orderId: number
  @Input() dugnadName: string
  @Input() isConfirmed: boolean
  @Input() orderStatus: OrderStatus
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<DugnadReminderHistoryResponse> = new Table()

  get Columns() {
    return Columns
  }

  get OrderStatus() {
    return OrderStatus
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  get ReminderType() {
    return ReminderType
  }

  constructor(
    private store: Store,
    private dugnadsService: DugnadsService
  ) {}

  ngOnInit() {
    this.table.columns = [
      Columns.Receiver,
      Columns.Type,
      Columns.Date,
      Columns.Email,
      Columns.PushNotification,
      Columns.SMS
    ]
    this.getReminderHistory()
  }

  private getReminderHistory(): void {
    this.table.loading = true
    this.dugnadsService
      .getDugnadReminders(this.orderId, this.selectedGroup.id)
      .pipe(finalize(() => (this.table.loading = false)))
      .subscribe((response) => (this.table.data = response.results))
  }

  openUserProfile(id: number): void {
    this.store.dispatch(new ToggleUserProfile(true, id))
  }

  onClose(shouldSendNewReminder: boolean): void {
    this.close.emit(shouldSendNewReminder)
  }
}
